import { createStore, combineReducers, applyMiddleware, compose } from 'redux';

//  Reducers
import appReducer from './reducers/app'
import homeReducer from './reducers/homes'
import userReducer from './reducers/users'
import securityUnitReducer from './reducers/security_units'
import messageReducer from './reducers/messages'
import eventReducer from './reducers/event'
import visitReducer from './reducers/visit'
import publicVisitorReducer from './reducers/public_visitor'
import productReducer from './reducers/products'
import accessTagLogReducer from './reducers/access_tag_log'
//Middleware
import {thunk} from 'redux-thunk'

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export default () => {
    const store = createStore(
        combineReducers({
            app: appReducer,
            users: userReducer,
            homes: homeReducer,
            security_units: securityUnitReducer,
            messages: messageReducer,
            events: eventReducer,
            visits: visitReducer,
            public_visitors: publicVisitorReducer,
            products: productReducer,
            access_tag_log: accessTagLogReducer,
        }),
        composeEnhancers(applyMiddleware(thunk))
    );
    return store;
};
